import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CircularProgress, Button } from "@mui/material";

export default function Footer() {
  const form = useRef();
  const [loading, setLoading] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);
    emailjs
      .sendForm(
        "service_ano5ffu",
        "template_7j51ano",
        form.current,
        "6vqxdHTxvc4gT-6TU"
      )
      .then(
        () => {
          setLoading(false);

          toast.success("Mesaj başarıyla gönderildi!");
        },
        (error) => {
          setLoading(false);

          toast.error("Mesaj gönderilemedi. Lütfen tekrar deneyin.");
          console.error("FAILED...", error.text);
        }
      );
  };

  return (
    <footer id="contact-us">
      <div className="container">
        <div className="footer-content">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="contact-form">
                <form ref={form} onSubmit={sendEmail}>
                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <fieldset>
                        <input
                          name="user_name"
                          type="text"
                          id="name"
                          placeholder="Ad Soyad"
                          required=""
                          style={{
                            backgroundColor: "rgba(250, 250, 250, 0.3)",
                          }}
                        />
                      </fieldset>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <fieldset>
                        <input
                          name="user_email"
                          type="email"
                          id="email"
                          placeholder="E-Posta"
                          required=""
                          style={{
                            backgroundColor: "rgba(250, 250, 250, 0.3)",
                          }}
                        />
                      </fieldset>
                    </div>
                    <div className="col-lg-12">
                      <fieldset>
                        <textarea
                          name="message"
                          rows="6"
                          id="message"
                          placeholder="Mesajınız"
                          required=""
                          style={{
                            backgroundColor: "rgba(250, 250, 250, 0.3)",
                          }}
                        ></textarea>
                      </fieldset>
                    </div>
                    {/* <div className="col-lg-12">
                      <fieldset>
                        <button
                          type="submit"
                          id="form-submit"
                          className="main-button"
                        >
                          Send Message Now
                        </button>
                      </fieldset>
                    </div> */}
                    <div className="col-lg-12">
                      <fieldset>
                        <Button
                          type="submit"
                          id="form-submit"
                          className="main-button"
                          disabled={loading}
                        >
                          {loading ? (
                            <CircularProgress size={24} />
                          ) : (
                            "Mesajı Gönder"
                          )}
                        </Button>
                      </fieldset>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="right-content col-lg-6 col-md-12 col-sm-12">
              <h2>
                <em>Sonerware Software</em> Hakkında Daha Fazla Bilgi
              </h2>
              <p>
                Sonerware Software'in yenilikçi çözümlerimizle iş hedeflerinize
                ulaşmanıza nasıl yardımcı olabileceğini keşfedin. Ekibimiz son
                teknoloji uygulamaları oluşturma ve uzman dijital danışmanlık
                sağlama konusunda uzmanlaşmıştır. Hizmetlerimiz ve size nasıl
                yardımcı olabileceğimiz hakkında daha fazla bilgi edinmek için
                iletişime geçin.
              </p>
              <ul className="social">
                <li>
                  <a href="#">
                    <i className="fa fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-linkedin"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-rss"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-dribbble"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="sub-footer">
              <p>
                Copyright &copy; 2020 Lava Landing Page | Designed by{" "}
                <a rel="nofollow" href="https://templatemo.com">
                  TemplateMo
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </footer>
  );
}
