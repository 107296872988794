import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebaseConfig";
import "../css/portfolioStyles.css";
import Lightbox from "../components/Lightbox"; 

export default function PortfolioPage() {
  const [apps, setApps] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [lightboxImages, setLightboxImages] = useState([]);
  const [lightboxIndex, setLightboxIndex] = useState(-1);

  useEffect(() => {
    const fetchProjects = async () => {
      const projectsCollection = collection(db, "apps");
      const projectSnapshot = await getDocs(projectsCollection);
      const projectList = projectSnapshot.docs.map((doc) => doc.data());
      projectList.sort((a, b) => a.no - b.no);
      setApps(projectList);
    };

    fetchProjects();
  }, []);

  const handlePlayVideo = (link) => {
    setSelectedVideo(link);
  };

  const handleCloseModal = () => {
    setSelectedVideo(null);
  };

  const handleOpenLightbox = (images, index) => {
    setLightboxImages(images);
    setLightboxIndex(index);
  };

  const handleCloseLightbox = () => {
    setLightboxImages([]);
    setLightboxIndex(-1);
  };

  const handlePrevImage = () => {
    setLightboxIndex((prevIndex) =>
      prevIndex === 0 ? lightboxImages.length - 1 : prevIndex - 1
    );
  };

  const handleNextImage = () => {
    setLightboxIndex((prevIndex) =>
      prevIndex === lightboxImages.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <section className="section" id="features">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="center-heading">
              <h2>
                {/* Our <em>Projects</em> */}
                Ürünlerimiz
              </h2>
            </div>
            <div className="apps-list">
              {apps.map((app) => (
                <div key={app.id} className="app-card">
                  <h3>{app.name}</h3>
                  <div className="carousel">
                    {app.images &&
                      app.images.map((image, index) => (
                        <img
                          key={index}
                          src={image}
                          alt={`${app.name} screenshot`}
                          onClick={() => handleOpenLightbox(app.images, index)}
                        />
                      ))}
                  </div>
                  <p>{app.description}</p>
                  {app.isPromoted && (
                    <button
                      className="play-button"
                      onClick={() => handlePlayVideo(app.link)}
                    >
                      ▶
                    </button>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {selectedVideo && (
        <div className="video-modal">
          <div className="video-content">
            <button className="close-modal" onClick={handleCloseModal}>
              ×
            </button>
            <iframe
              src={`https://www.youtube.com/embed/${new URL(selectedVideo).searchParams.get('v')}`}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      )}
      {lightboxImages.length > 0 && lightboxIndex > -1 && (
        <Lightbox
          images={lightboxImages}
          currentIndex={lightboxIndex}
          onClose={handleCloseLightbox}
          onPrev={handlePrevImage}
          onNext={handleNextImage}
        />
      )}
    </section>
  );
}
