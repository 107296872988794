
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCkkTDaImx0hpm-HXBHVeDSQHvoBK5ctxY",
  authDomain: "sonerware-software-tr.firebaseapp.com",
  projectId: "sonerware-software-tr",
  storageBucket: "sonerware-software-tr.appspot.com",
  messagingSenderId: "719649156436",
  appId: "1:719649156436:web:3347c3af03723e02916cc1",
  measurementId: "G-YN4QV4353N"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
// const auth = getAuth(app);

export { db };


