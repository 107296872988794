import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCustomerRates } from "../slices/customerRateSlice";

export default function TestimonialsPage() {
  const dispatch = useDispatch();
  const { rates, status, error } = useSelector((state) => state.customerRates);

  useEffect(() => {
    dispatch(fetchCustomerRates());
  }, [dispatch]);

  if (status === "loading") {
    return <div>Yükleniyor...</div>;
  }

  if (status === "failed") {
    return <div>Hata: {error}</div>;
  }

  return (
    <section className="section" id="testimonials">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="center-heading">
              <h2>
                <em>Hakkımızda</em> Ne Düşünüyorlar
              </h2>
              <p>
                Bizimle olumlu deneyimlerini paylaşan çok sayıda müşteriyle
                çalışmaktan gurur duyuyoruz. Aşağıda, projelerini bize emanet
                edenlerden gelen gerçek geri bildirimleri bulacaksınız. Yüksek
                kaliteli çözümler sunma taahhüdümüzün, onların nazik sözlerinde
                yansıdığına inanıyoruz. Müşterilerimizin bizimle çalışma
                hakkında neler söylediklerine bakın{" "}
              </p>
            </div>
          </div>

          <div className="col-lg-10 col-md-12 col-sm-12 mobile-bottom-fix-big">
            <div className="owl-carousel owl-theme">
              {rates.map((rate) => (
                <div key={rate.id} className="item service-item">
                  <div className="author">
                    <i>
                      <img
                        src={rate.imageUrl}
                        alt={`${rate.name} ${rate.surname}`}
                      />
                    </i>
                  </div>
                  <div className="testimonial-content">
                    <ul className="stars">
                      {Array(Math.round(rate.rate))
                        .fill(0)
                        .map((_, i) => (
                          <li key={i}>
                            <i className="fa fa-star"></i>
                          </li>
                        ))}
                    </ul>
                    <h4>{`${rate.name} ${rate.surname}`}</h4>
                    <p>{rate.opinion}</p>
                    <span>{rate.appName}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
