import React from "react";

export default function WelcomePage() {
  return (
    <div className="welcome-area" id="welcome">
      <div className="header-text">
        <div className="container">
          <div className="row">
            <div
              className="left-text col-lg-6 col-md-12 col-sm-12 col-xs-12"
              data-scroll-reveal="enter left move 30px over 0.6s after 0.4s"
            >
              <h1>
                <em>Dijital Dönüşümde</em> Ortağınız
              </h1>
              <p>
                Misyonumuz, verimliliği artıran, büyümeyi teşvik eden ve yeni
                fırsatlar yaratan yenilikçi yazılım çözümleri sunarak
                işletmelere güç vermektir. Kişiye özel hizmetlerimizin
                hedeflerinize ulaşmanıza nasıl yardımcı olabileceğini keşfedin.
              </p>{" "}
              <a href="#about" className="main-button-slider">
                BİZİ DAHA İYİ TANIYIN
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
