import "./css/snackbarStyles.css";
import { Outlet } from "react-router-dom";
import React, { useEffect, useState } from "react";

function App() {
  const [showSnackbar, setShowSnackbar] = useState(false);

  useEffect(() => {
    const userLanguage = navigator.language || navigator.userLanguage;

    fetch("https://ipapi.co/json/")
      .then((response) => response.json())
      .then((data) => {
        const userCountry = data.country;

        if (!userLanguage.startsWith("tr") || userCountry !== "TR") {
          setShowSnackbar(true);
        }
      })
      .catch((error) => console.log("Konum tespiti yapılamadı:", error));
  }, []);

  const dismissSnackbar = () => {
    localStorage.setItem("languageChoice", "dismissed");
    setShowSnackbar(false);
  };

  return (
    <div className="App">
      <Outlet />
      {showSnackbar && (
        <div id="language-snackbar" className="snackbar">
          Would you like to visit the English version of the site?
          <a
            href="https://sonerwaresoftware.com"
            className="snackbar-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            English Version
          </a>
          <button onClick={dismissSnackbar} className="snackbar-dismiss">
            Dismiss
          </button>
        </div>
      )}
    </div>
  );
}

export default App;
