import React from "react";

export default function ShowroomPage() {
  return (
    <section className="section" id="promotion">
      <div className="container">
        <div className="row">
          <div
            className="left-image col-lg-5 col-md-12 col-sm-12 mobile-bottom-fix-big"
            data-scroll-reveal="enter left move 30px over 0.6s after 0.4s"
          >
            <img
              src="images/left-image.png"
              className="rounded img-fluid d-block mx-auto"
              alt="App"
            />
          </div>
          <div className="right-text offset-lg-1 col-lg-6 col-md-12 col-sm-12 mobile-bottom-fix">
            <ul>
              <li data-scroll-reveal="enter right move 30px over 0.6s after 0.4s">
                <img src="images/about-icon-01.png" alt="" />
                <div className="text">
                  <h4>Cross-Platform</h4>
                  <p>
                    Hem iOS hem de Android'de kusursuz çalışan uygulamalar
                    oluşturun. Tüm cihazlarda tutarlı performansın keyfini
                    çıkarın.
                  </p>{" "}
                </div>
              </li>
              <li data-scroll-reveal="enter right move 30px over 0.6s after 0.5s">
                <img src="images/about-icon-02.png" alt="" />
                <div className="text">
                  <h4>Gelişmiş Performans ve Hız</h4>{" "}
                  <p>
                    Sorunsuz kullanıcı deneyimleriyle hızlı, duyarlı uygulamalar
                    elde edin. Daha iyi kullanıcı memnuniyeti için performansı
                    ve hızı optimize edin.
                  </p>
                </div>
              </li>
              <li data-scroll-reveal="enter right move 30px over 0.6s after 0.6s">
                <img src="images/about-icon-03.png" alt="" />
                <div className="text">
                  <h4>Hızlı Geliştirme ve Güncellemeler</h4>{" "}
                  <p>
                    Geliştirmeyi ve güncellemeleri hızlandırın. Verimli
                    süreçlerle pazar değişikliklerine ve kullanıcı ihtiyaçlarına
                    hızla uyum sağlayın.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}
