import React from "react";

export default function FeaturesPage() {
  return (
    <section className="section" id="about">
      <div className="container">
        <div className="row">
          <div
            className="col-lg-4 col-md-6 col-sm-12 col-xs-12"
            data-scroll-reveal="enter left move 30px over 0.6s after 0.4s"
          >
            <div className="features-item">
              <div className="features-icon">
                <h2>01</h2>
                <img src="images/features-icon-1.png" alt="" />
                <h4>Mobil Uygulama Geliştirme</h4>
                <p>
                  Yüksek performanslı mobil uygulamalar
                  oluşturuyoruz. Uygulamalarımız hem iOS hem de Android
                  cihazlarda mükemmel şekilde çalışıyor.
                </p>{" "}
                <a href="#promotion" className="main-button">
                  Daha Fazlasını Okuyun
                </a>
              </div>
            </div>
          </div>
          <div
            className="col-lg-4 col-md-6 col-sm-12 col-xs-12"
            data-scroll-reveal="enter bottom move 30px over 0.6s after 0.4s"
          >
            <div className="features-item">
              <div className="features-icon">
                <h2>02</h2>
                <img src="images/features-icon-2.png" alt="" />
                <h4>Web Geliştirme</h4>
                <p>
                  Modern ve kullanıcı dostu web siteleri tasarlıyoruz.
                  Profesyonel web çözümlerimizle çevrimiçi varlığınızı
                  güçlendirin.
                </p>
                <a href="#promotion" className="main-button">
                  Daha Fazlasını Keşfedin
                </a>
              </div>
            </div>
          </div>
          <div
            className="col-lg-4 col-md-6 col-sm-12 col-xs-12"
            data-scroll-reveal="enter right move 30px over 0.6s after 0.4s"
          >
            <div className="features-item">
              <div className="features-icon">
                <h2>03</h2>
                <img src="images/features-icon-3.png" alt="" />
                <h4>Dijital Danışmanlık</h4>
                <p>
                  Dijital dönüşüm danışmanlık hizmetleri sunuyoruz. İşletmenize
                  rekabetçi bir üstünlük kazandırmak için teknolojik çözümler
                  sağlıyoruz.
                </p>
                <a href="#contact-us" className="main-button">
                  Daha Fazla Detay
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
