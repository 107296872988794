import React from 'react'
import logo from '../assets/logo/ss_logo.png'

export default function Navigation() {
  return (
    <header className="header-area header-sticky">
          <div className="container">
              <div className="row">
                  <div className="col-12">
                      <nav className="main-nav">
                          {/* <a href="#welcome" className="logo">
                              Sonerware
                          </a> */}

                            <a href="#welcome" className="logo">
                                <img src={ logo } alt="logo" /> 
                                <div>
                                    Sonerware <br />
                                    <span style={{ fontSize: '14px', fontWeight: 'normal', display: 'block', lineHeight: '1.2' }}>Software</span>
                                </div>
                            </a>
                          <ul className="nav">
                              <li className="scroll-to-section"><a href="#welcome" className="menu-item">ANASAYFA</a></li>
                              <li className="submenu">
                                  <a href="#features" className="menu-item">ÜRÜNLERİMİZ</a>
                                  <ul>
                                      <li className="scroll-to-section"><a href="#features" className="menu-item">ÇEYİZBUDDY</a></li>
                                      <li className="scroll-to-section"><a href="#features" className="menu-item">DT. BETÜL MUMCU KLİNİK</a></li>
                                      <li className="scroll-to-section"><a href="#features" className="menu-item">DÜNYAMIZI TANIYALIM</a></li>
                                      <li className="scroll-to-section"><a href="#features" className="menu-item">NEYAPPSAM</a></li>
                                      <li className="scroll-to-section"><a href="#features" className="menu-item">EZTRIP</a></li>
                                      <li className="scroll-to-section"><a href="#features" className="menu-item">DT. FEHMİ BORA ŞARİÇ KLİNİK</a></li>
                                  </ul>
                              </li>
                              <li className="scroll-to-section"><a href="#about" className="menu-item">HAKKIMIZDA</a></li>
                              {/* <li className="scroll-to-section"><a href="#promotion" className="menu-item">Features</a></li> */}
                              <li className="scroll-to-section"><a href="#testimonials" className="menu-item">REFERANSLAR</a></li>
                              <li className="scroll-to-section"><a href="#contact-us" className="menu-item">İLETİŞİM</a></li>
                          </ul>
                          <a className='menu-trigger'>
                              <span>Menu</span>
                          </a>
                      </nav>
                  </div>
              </div>
          </div>
      </header>
  )
}

